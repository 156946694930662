import "./alarm.css";
import React from 'react';
import axios from "axios";


function Alarm() {
    const notifToBack=()=>{
const params = new URLSearchParams(window.location.search);
const desk=params.get('desk');

    axios.post('http://test.atlascaferest.com/post.php?desk='+desk,);
alert('همکاران ما جهت سفارش گیری به زودی مراجعه خواهند کرد');
   }

   const GotoUp=()=> {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
   }

    return(<>
<img className="waiter" src="/assets/images/waiter.png" alt="" onClick={()=>notifToBack()}></img>

<button onClick={()=>GotoUp()} id="myBtn" title="Go to top"><img src="/assets/images/up-arrow.png" width="24" height="24" alt="arrow-up"></img></button>

</>  );
}
export default Alarm;


import MenuBody from "./components/MenuBody";
import Navbar from "./components/Navbar";
import Alarm from "./components/Alarm";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return ( <Router>
  <div>
    <Navbar/>
    <MenuBody/>
  </div>
  <Routes>
  <Route path="/desk" element={<Alarm/>}/>
  </Routes>
  </Router>
  );
}

export default App;

export const starter_iranian = {
headimg:true,
leaderCat:"منوی ایرانی",
cat:"پیش غذای ایرانی",
id:"starteriranian",
foods:[
  {
    title: "سالاد فصل",
    price: "85",
    details:"",
    image:"/assets/images/salad-fasl.jpg"
  },
  {
    title: "ماست",
    price: "25",
    details:"",
    image:"/assets/images/noimage.jpg"
  },
  {
    title: "ماست موسیر",
    price: "35",
    details:"",
    image:"/assets/images/noimage.jpg"
  },
  {
    title: "ماست خیار",
    price: "35",
    details:"",
    image:"/assets/images/noimage.jpg"
  },
]
}

export const kebab_iranian = {
  leaderCat:"منوی ایرانی",
  cat:"کباب های ایرانی",
  id:"iraniankebab",
  foods:[
    {
      title: "شیشلیک",
      price: "370",
      details:"450 گرم گوشت راسته گوسفندی، دورچین مخصوص",
      image:"/assets/images/shishlik.jpg"
    },
    {
      title: "لقمه",
      price: "200",
      details:"250 گرم گوشت چرخ شده گوسفندی، دورچین مخصوص",
      image:"/assets/images/loghme.jpg"
    },
    {
      title: "جوجه با استخوان",
      price: "230",
      details:"250 گرم جوجه با استخوان، دورچین مخصوص",
      image:"/assets/images/juje-ba-ostokhan.jpg"
    },
    {
      title: "جوجه بدون استخوان",
      price: "170",
      details:"250 گرم جوجه بدون استخوان، دورچین مخصوص",
      image:"/assets/images/juje-bi-ostokhan.jpg"
    },
    {
      title: "گردن مخصوص",
      price: "370",
      details:"550 گرم گردن گوسفندی، دورچین مخصوص، سس سیر و پیاز سرآشپز",
      image:"/assets/images/gardan-makhsus.jpg"
    },
    {
      title: "ماهیچه",
      price: "370",
      details:"550 گرم ماهیچه گوسفندی، دورچین مخصوص، سس سیر سرآشپز",
      image:"/assets/images/mahiche-makhsus.jpg"
    },
    {
      title: "ژیگو بوقلمون ویژه اطلس",
      price: "750",
      details:"1.5 کیلوگرم ران بوقلمون، دورچین مخصوص، سس سیر و پیاز سرآشپز",
      image:"/assets/images/noimage.jpg"
    },
  ]
  }

  
export const rice_iranian = {
  rotatehead:true,
  leaderCat:"منوی ایرانی",
  cat:"پلو های ایرانی",
  id:"iranianrice",
  foods:[
    {
      title: "پلو ایرانی",
      price: "60",
      details:"350 گرم پلو ایرانی، ته چین زعفرانی، کره",
      image:"/assets/images/noimage.jpg"
    },
    {
      title: "باقالی پلو",
      price: "80",
      details:"",
      image:"/assets/images/noimage.jpg"
    },
  ]
  }

export const Prefood_ar = {
headimg:true,
leaderCat:"منوی عربی",
cat:"مقبلات (پیش غذا)",
id:"arabicprefood",
foods:
[
    {
      title: "سالاد فتوش",
      price: "175",
      details:"کاهو، نعناع، خرفه، نان سرخ شده، سبزیجات، سس مخصوص",
      image:"/assets/images/salad-fatoush.jpg"
    },
    {
      title: "سالاد شاورما",
      price: "245",
      details:"شاورمای مرغ، کاهو ،نان سرخ شده، گوجه چری، سس مخصوص",
      image:"/assets/images/salad-shawarma.jpg"
    },    {
      title: "باباغنوش",
      price: "95",
      details:"بادمجان کبابی، سبزیجات، ادویه جات مخصوص",
	  image:"/assets/images/BabaGhanoush.jpg"
    },    {
      title: "حمص",
      price: "75",
      details:"نخود، ارده، روغن زیتون",
	  image:"/assets/images/homos.jpg"
    },    {
      title: "تبوله",
      price: "80",
      details:"جعفری، بلغور عربی، سبزیجات، لیمو، روغن زیتون",
	   image:"/assets/images/salad-taboule.jpg"
    },    {
      title: "متبل",
      price: "90",
      details:"بادمجان کبابی، لبنه، ارده، سیر، روغن زیتون و ...",
	   image:"/assets/images/matbal.jpg"
    },    {
      id:1,
      title: "بطاطا حاره",
      price: "85",
      details:"سیب زمینی لبنانی، سس مخصوص اسپایسی",
	   image:"/assets/images/battata-hare.jpg"
    },    {
      title: "کبه",
      price: "180",
      details:"گوشت چرخ کرده با ادویه مخصوص، بلغور عربی",
	   image:"/assets/images/kobe.jpg"
    }, {
      title: "حمص باللحم",
      price: "210",
      details:"راسته گوسفندی، چلغوز، روغن حیوانی، حمص و...",
	   image:"/assets/images/homos-bel-laham.jpg"
    }, {
      title: "میکس مقبلات",
      price: "210",
      details:"تبوله، حمص، متبل، باباغنوش",
	   image:"/assets/images/mix-moghbalat.jpg"
    }, {
      title: "شوربه عدس",
      price: "85",
      details:"دال عدس، عصاره ماهیچه، ادویه مخصوص",
	   image:"/assets/images/soup-shurbe-adas.jpg"
    },
  ]

}

export const managhish = {
  leaderCat:"منوی عربی",
  cat:"مناقیش",
  id:"arabicprefood2",
  foods:
  [
      {
        title: "زعتر بالجبن",
        price: "160",
        details:"پنیر، زعتر، روغن، خمیر، دورچین",
        image:"/assets/images/noimage.jpg"
      },
      {
        title: "لحم بالعجین (صفیحه)",
        price: "230",
        details:"گوشت چرخ کرده طعم دار، خمیر، دورچین، ادویه مخصوص",
        image:"/assets/images/sharhat-laham-belajin.jpg"
      },    {
        title: "مجرمشه",
        price: "365",
        details:"راسته گوسفندی، چربی، ادویه مخصوص، خمیر",
		 image:"/assets/images/noimage.jpg"
      },    {
        title: "جبنه",
        price: "180",
        details:"پنیر هالومی، موزارلا، خمیر، ادویه",
		 image:"/assets/images/noimage.jpg"
      },    {
        title: "شیش طاووق (مخصوص تنوری)",
        price: "210",
        details:"سینه مرغ، ادویه، خمیر",
		 image:"/assets/images/shish-tavough.jpg"
      },    {
        title: "شرحات لحم",
        price: "360",
        details:"استیک گوسفندی طعم دار شده تنوری",
		 image:"/assets/images/sharhat-laham.jpg"
      },    {
        id:1,
        title: "پیتزا شاورما",
        price: "270",
        details:"شاورما گوشت و مرغ، پنیر، ذرت، قارچ، فلفل دلمه، زیتون",
		 image:"/assets/images/Pizza-Shawarma.jpg"
      }
    ]
  }

  
export const shawarma = {
  leaderCat:"منوی عربی",
  cat:"شاورما",
  id:"arabicprefood3",
  foods:
  [
      {
        title: "شاورما عربی دجاج (مرغ)",
        price: "210",
        details:"استیک مرغ طعم دار شده، نان مخصوص، دورچین، سس، ادویه مخصوص",
        image:"/assets/images/shawarma-arabi-dejaj.jpg"
      },
      {
        title: "شاورما عربی لحم (گوشت)",
        price: "275",
        details:"150 گرم گوشت بره، نان مخصوص، سس، دورچین",
        image:"/assets/images/shawarma-laham-arabi.jpg"
      },    {
        title: "شاورما صحن دجاج (مرغ)",
        price: "280",
        details:"300 گرم پرس باز مرغ، سس مخصوص، دورچین",
		 image:"/assets/images/shawarma-sahn-dejaj.jpg"
      },    {
        title: "شاورما صحن لحم (گوشت)",
        price: "380",
        details:"300 گرم پرس باز گوشت، دورچین",
		 image:"/assets/images/shawarma-sahn-laham.jpg"
      }, 
    ]
  }

   
export const mashawi = {
  leaderCat:"منوی عربی",
  cat:"مشاوی (کباب ها)",
  id:"arabicprefood4",
  foods:
  [
      {
        title: "کباب حلبی",
        price: "250",
        details:"300 گرم گوشت گوسفندی، سس مخصوص، دورچین مخصوص",
        image:"/assets/images/kabab-halabi.jpg"
      },
      {
        title: "کباب باتینجان",
        price: "230",
        details:"300 گرم گوشت گوسفندی، بادمجان کبابی، دورچین مخصوص",
        image:"/assets/images/kebab-batinjan.jpg"
      },    {
        title: "شقف لحم",
        price: "365",
        details:"300 گرم گوشت راسته، دورچین مخصوص",
		 image:"/assets/images/shaghaf-laham.jpg"
      },    {
        title: "شیش طاووق",
        price: "250",
        details:"300 گرم مرغ طعم دار شده، سس مخصوص، ادویه مخصوص، دورچین",
		 image:"/assets/images/shish-tavough.jpg"
      },    {
        title: "سمک مشوی",
        price: "650",
        details:"1200 گرم ماهی دریایی سنگسر طعم دار شده ذغالی",
		 image:"/assets/images/noimage.jpg"
      },    {
        title: "فروج",
        price: "290",
        details:"800 گرم مرغ ارگانیک طعم دار شده ذغالی",
		 image:"/assets/images/farouj.jpg"
      },    {
        title: "ریش غنم",
        price: "385",
        details:"450 گرم راسته گوسفندی، دورچین مخصوص",
		 image:"/assets/images/rish-ghanam.jpg"
      },    {
        title: "مشاوی مشکله",
        price: "890",
        details:"یک سیخ شیش طاووق، یک سیخ شقف، یک پرس کباب حلبی، 4 عدد ریش غنم",
		 image:"/assets/images/noimage.jpg"
      }
    ]
  }

  export const manasef = {
    rotatehead:true,
    leaderCat:"منوی عربی",
    cat:"مناسف (پلوهای عربی)",
    id:"arabicprefood5",
    foods:
    [
        {
          title: "مقلوبه باللحم",
          price: "470",
          details:"400 گرم گوشت گوسفندی، ته چین سبزیجات، برنج با ادویه مخصوص",
          image:"/assets/images/noimage.jpg"
        },
        {
          title: "مندی",
          price: "465",
          details:"400 گرم گوشت تنوری، برنج عربی",
          image:"/assets/images/noimage.jpg"
        }
      ]
    }

    export const cafe = {
      headimg:true,
      leaderCat:"کافه",
      cat:"کافی بار",
      id:"coffeebar",
      foods:
      [
          {
            title: "اسپرسو",
            price: "47",
            details:"قهوه عصاره گیری شده با دستگاه",
            image:"/assets/images/spresso.jpg"
          },
          {
            title: "آمریکانو",
            price: "47",
            details:"اسپرسو، آب جوش",
            image:"/assets/images/americano.jpg"
          },
          {
            title: "لاته",
            price: "73",
            details:"اسپرسو، شیر بخار داده شده",
            image:"/assets/images/latte.jpg"
          }
          ,
          {
            title: "کاپوچینو",
            price: "71",
            details:"اسپرسو، شیر بخار داده شده",
            image:"/assets/images/caouccino.jpg"
          },
          {
            title: "کارامل ماکیاتو",
            price: "79",
            details:"اسپرسو، شیر بخارداده شده، کارامل",
            image:"/assets/images/caramel-makiato.jpg"
          },
          {
            title: "سامر موکا",
            price: "79",
            details:"اسپرسو، شیر بخارداده شده، شکلات",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "کورتادو",
            price: "71",
            details:"اسپرسو، شیر بخارداده شده",
            image:"/assets/images/cortado.jpg"
          },
          {
            title: "گلدن لاته",
            price: "115",
            details:"اسپرسو، شیر بخارداده شده، ورق طلا",
            image:"/assets/images/goldenlatte.jpg"
          },
          {
            title: "آفوگاتو",
            price: "83",
            details:"اسپرسو، بستنی",
            image:"/assets/images/affogato.jpg"
          },
          {
            title: "وانیلا",
            price: "79",
            details:"اسپرسو، شیر بخارداده شده، وانیل",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "وی 60",
            price: "87",
            details:"پور اور متد",
            image:"/assets/images/v60.jpg"
          },
          {
            title: "فرنچ پرس",
            price: "87",
            details:"ایمرشن متد",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "ترکیش کافی",
            price: "47",
            details:"جذوه، ایبریک",
            image:"/assets/images/turkishcoffe.png"
          },
          {
            title: "شیخ کافی",
            price: "63",
            details:"قهوه عربی",
            image:"/assets/images/noimage.jpg"
          }
        ]
      }
      
  export const signature = {
    leaderCat:"کافه",
    cat:"سیگنچر بار",
    id:"signaturebar",
    foods:
    [
        {
          title: "سیگنچر کریمی نات کرم",
          price: "75",
          details:"فندق، شیر، اسپرسو",
          image:"/assets/images/signature-creamy-nutcream.jpg"
        },
        {
          title: "سیگنچر وانیلا کوکی سرد",
          price: "87",
          details:"گرانول، کوکی، بستنی",
          image:"/assets/images/noimage.jpg"
        },
        {
          title: "سیگنچر پیستاچیو سرد",
          price: "93",
          details:"پسته، موز، اسپایس",
          image:"/assets/images/signature-pistasio.jpg"
        }
      ]
    }

    export const tea = {
      leaderCat:"کافه",
      cat:"چای بار و دم نوش",
      id:"tea",
      foods:
      [
          {
            title: "دمنوش کلاسیک",
            price: "57",
            details:"گل گاو زبان، بهارنارنج، چای ترش، به لیمو",
            image:"/assets/images/classic-damnoosh.jpg"
          },
          {
            title: "دمنوش تخصصی",
            price: "63",
            details:"رویبوش، جینجر توئیست، استرابری کیوی",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "چای سبز",
            price: "57",
            details:"چای سبز دم شده",
            image:"/assets/images/noimage.jpg"
          },
          {
            title:"چای سیاه با سرویس",
            price: "35",
            details:"چای سیاه دم شده",
            image:"/assets/images/service-blacktea.jpg"
          },
          {
            title: "چای سیاه ساده",
            price: "10",
            details:"چای سیاه دم شده",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "چای زعفران",
            price: "43",
            details:"چای سیاه به همراه زعفران",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "چای مراکشی",
            price: "63",
            details:"نعنا، نبات",
            image:"/assets/images/morocco-tea.jpg"
          },
          {
            title: "چای عربین نایت",
            price: "57",
            details:"چای سیاه، ادویه عربی",
            image:"/assets/images/arabian-night.jpg"
          },
          {
            title: "چای ماسالا",
            price: "57",
            details:"ادویه ماسالا، شیر",
            image:"/assets/images/massala-tea.jpg"
          }
        ]
      }

      
    export const garmnoosh = {
      leaderCat:"کافه",
      cat:"گرمنوش",
      id:"garmnoosh",
      foods:
      [
          {
            title: "دیپ نوتلا",
            price: "59",
            details:"شکلات داغ، شکلات فندق",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "وایت پینات چاکلت",
            price: "78",
            details:"کره بادام زمینی، شکلات سفید",
            image:"/assets/images/white-pnut-chocholate.jpg"
          },
          {
            title: "نات چاکلت",
            price: "57",
            details:"شکلات داغ، فندق، نارگیل",
            image:"/assets/images/noimage.jpg"
          }
        ]
      }
      
    export const cold_cafe = {
      leaderCat:"کافه",
      cat:"کلد کافئین",
      id:"coldcafe",
      foods:
      [
          {
            title: "آیسد لاته",
            price: "78",
            details:"اسپرسو، شیر، یخ",
            image:"/assets/images/noimage.jpg"
          },
          {
            title: "آیسد کارامل ماکیاتو",
            price: "84",
            details:"اسپرسو، شیر، یخ، کارامل",
            image:"/assets/images/iced-caramel-makiato.jpg"
          },
          {
            title: "آیسد موکا",
            price: "84",
            details:"اسپرسو، شیر ، یخ، شکلات",
            image:"/assets/images/iced-mocca.jpg"
          },
          {
            title:"آیسد وانیلا",
            price: "84",
            details:"اسپرسو، شیر، یخ، وانیل",
            image:"/assets/images/noimage.jpg"
          }
        ]
      }

  
      export const shake = {
        leaderCat:"کافه",
        cat:"شیک و اسموتی",
        id:"shake",
        foods:
        [
            {
              title: "شیک نوتلا",
              price: "117",
              details:"وانیل، نوتلا",
              image:"/assets/images/shake-nutella.jpg"
            },
            {
              title: "شیک پینات باتر",
              price: "91",
              details:"وانیل، کره بادام زمینی",
              image:"/assets/images/shake-pnut-potter.jpg"
            },
            {
              title: "شیک بنانا چاکلت",
              price: "95",
              details:"اسنیکرز، موز",
              image:"/assets/images/noimage.jpg"
            },
            {
              title:"شیک بلک اورئو",
              price: "93",
              details:"بستنی ذغالی، ارئو",
              image:"/assets/images/shake-black-oreo.jpg"
            },
            {
              title:"اسموتی منگو اسپایس",
              price: "83",
              details:"انبه، انجیر، دارچین، زنجبیل",
              image:"/assets/images/noimage.jpg"
            },
            {
              title:"اسموتی رایکا",
              price: "87",
              details:"پوره بری، میکس قرمز",
              image:"/assets/images/smoothy-raika.jpg"
            },
            {
              title:"اسموتی سوربری",
              price: "91",
              details:"انار، آلبالو، لواشک",
              image:"/assets/images/smoothy-surbery.jpg"
            },
            {
              title:"اسموتی وایت پشن",
              price: "93",
              details:"آلوورا، پشن فروت، موز",
              image:"/assets/images/smoothy-white-passion.jpg"
            },
            {
              title:"اسموتی آووکادو",
              price: "117",
              details:"آووکادو، نارگیل، عسل",
              image:"/assets/images/smoothy-avocado.jpg"
            },
            {
              title:"اسموتی ملونیکا",
              price: "75",
              details:"طالبی، نارگیل، وانیل",
              image:"/assets/images/smoothy-melonica.jpg"
            }
          ]
        }
  
        export const moctail = {
          rotatehead:true,
          leaderCat:"کافه",
          cat:"ماکتیل",
          id:"moctail",
          foods:
          [
              {
                title: "ماکتیل ایگور",
                price: "95",
                details:"تروپیکال، پوره شاتوت، آلوورا",
                image:"/assets/images/moctail-igore.jpg"
              },
              {
                title: "ماکتیل کاکتوس",
                price: "98",
                details:"پوره انبه، پشن فروت، کاکتوس",
                image:"/assets/images/moctail-cactus.jpg"
              },
              {
                title: "ماکتیل بلو سی",
                price: "89",
                details:"آناناس، نارگیل، بلوکارسائو",
                image:"/assets/images/moctail-bluesea.jpg"
              },
              {
                title:"لیموناد",
                price: "58",
                details:"لیمو، سودا",
                image:"/assets/images/noimage.jpg"
              },
              {
                title:"موهیتو کلاسیک",
                price: "58",
                details:"لیمو، نعنا، سودا",
                image:"/assets/images/mojito-classic.jpg"
              },
              {
                title:"ماکتیل پرپل کلو",
                price: "93",
                details:"لوندر، میخک، پشن فروت",
                image:"/assets/images/moctail-purple-clue.jpg"
              },
              {
                title:"ماکتیل پینک",
                price: "89",
                details:"آلوورا، لیمو، تروپیکال",
                image:"/assets/images/moctail-ping.jpg"
              },
              {
                title:"ماکتیل چیا پانچ لوندر",
                price: "88",
                details:"لوندر، چیا، عسل، عرقیجات",
                image:"/assets/images/moctail-cia-ponch-lavender.jpg"
              },
              {
                title:"ماکتیل بلک سان رایز",
                price: "87",
                details:"پرتقال، گرانادین",
                image:"/assets/images/moctail-black-sunrise.jpg"
              },
              {
                title:"ماکتیل گریتوس",
                price: "91",
                details:"سیب سبز، زنجبیل، لیمو",
                image:"/assets/images/moctail-gritus.jpg"
              }
            ]
          }
  
          export const cake = {
            headimg:true,
            leaderCat:"کیک و دسر",
            cat:"",
            id:"cake",
            foods:
            [
                {
                  title: "کیک روز",
                  price: "95",
                  details:"از ما بپرسید",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title: "چیز کیک روز",
                  price: "97",
                  details:"از ما بپرسید",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title: "هاویج",
                  price: "120",
                  details:"",
                  image:"/assets/images/havij.jpg"
                },
                {
                  title:"خرما ارده",
                  price: "120",
                  details:"",
                  image:"/assets/images/khorma-arde.jpg"
                },
                {
                  title:"بقلاوه مشکله",
                  price: "140",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"کنافه",
                  price: "120",
                  details:"",
                  image:"/assets/images/kanaffe.jpg"
                },
                {
                  title:"کنافه نابلسیه",
                  price: "130",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"معمول مشکل",
                  price: "110",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"مدلوقه",
                  price: "110",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"هریسه/بسبوسه",
                  price: "115",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"لیالی لبنان",
                  price: "90",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                },
                {
                  title:"حلاوه جبن",
                  price: "125",
                  details:"",
                  image:"/assets/images/noimage.jpg"
                }
              ]
            }
    
            export const softdrink = {
              leaderCat:"سافت درینک",
              cat:"",
              id:"softdrink",
              foods:
              [
                  {
                    title: "آبمیوه طبیعی فصل",
                    price: "53",
                    details:"از ما بپرسید",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title: "آب معدنی",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title: "نوشابه",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title:"سودا ایتالیایی",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title:"آب جو بدون الکل باواریا",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title:"دوغ آیران",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  },
                  {
                    title:"شامی",
                    price: "...",
                    details:"",
                    image:"/assets/images/noimage.jpg"
                  }
                ]
              }
      
  

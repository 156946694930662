import { managhish, mashawi, Prefood_ar, shawarma, starter_iranian,kebab_iranian,rice_iranian, manasef, cafe, signature, tea, garmnoosh, cold_cafe, shake, moctail, cake, softdrink } from "../data/data";
import "./menuBody.css";
import MenuLeft from "./MenuLeft";
import MenuRight from "./MenuRight";
import Alarm from "./Alarm";
import { Link } from "react-scroll";
function MenuBody() {

    return ( 
<body>
    <div className="body-t">
<section className="body-in">
    <div className="banner-container"><img className="banner-img" src="/assets/images/restaurant-desk.jpeg" alt="عکس بنر منو رستوران اطلس"></img></div>
 
<div className="sec-2">
    <div className="desc1">ساعات کار: 12ظهر الی 12شب</div>
    <div className="titr1">
        <div className="decor-l">
        <svg xmlns="http://www.w3.org/2000/svg" width="41.125" height="9.146">
<path fill="none" stroke="#9C7C57" stroke-miterlimit="10" d="M40.881 8.576L20.562.591.244 8.576"></path>
<path fill="none" stroke="#9C7C57" stroke-miterlimit="10" d="M40.881.591L20.562 8.576.243.591"></path>
</svg>
        </div>
        منوی اصلی
        <div className="decor-r">
        <svg xmlns="http://www.w3.org/2000/svg" width="41.125" height="9.146">
<path fill="none" stroke="#9C7C57" stroke-miterlimit="10" d="M40.881 8.576L20.562.591.244 8.576"></path>
<path fill="none" stroke="#9C7C57" stroke-miterlimit="10" d="M40.881.591L20.562 8.576.243.591"></path>
</svg>
        </div>
        </div>
</div>

<section>
    <Alarm/>
    <div className="menu-buttons">
    <Link className="menu-nav" activeClass="active" to={starter_iranian.id} spy={true} smooth={true} offset={50} duration={500} >منوی ایرانی</Link>
    <Link className="menu-nav" activeClass="active" to={Prefood_ar.id} spy={true} smooth={true} offset={50} duration={500} >منوی عربی</Link>
    <Link className="menu-nav" activeClass="active" to={cafe.id} spy={true} smooth={true} offset={50} duration={500} >کافه</Link>
    <Link className="menu-nav" activeClass="active" to={cake.id} spy={true} smooth={true} offset={50} duration={500} >دسر و دم نوش</Link>
</div>
<div className="menuleft">
<MenuLeft menu={starter_iranian}/>
</div>
<div className="menuleft">
<MenuRight menu={kebab_iranian}/>
</div>
<div className="menuleft">
<MenuLeft menu={rice_iranian}/>
</div>
<div className="menuleft">
<MenuRight menu={Prefood_ar}/>
</div>
<div className="menuleft">
<MenuLeft menu={managhish}/>
</div>
<div className="menuleft">
<MenuRight menu={shawarma}/>
</div>
<div className="menuleft">
<MenuLeft menu={mashawi}/>
</div>
<div className="menuleft">
<MenuRight menu={manasef}/>
</div>
<div className="menuleft">
<MenuLeft menu={cafe}/>
</div>
<div className="menuleft">
<MenuRight menu={signature}/>
</div>
<div className="menuleft">
<MenuLeft menu={tea}/>
</div>
<div className="menuleft">
<MenuRight menu={garmnoosh}/>
</div>
<div className="menuleft">
<MenuLeft menu={cold_cafe}/>
</div>
<div className="menuleft">
<MenuRight menu={shake}/>
</div>
<div className="menuleft">
<MenuLeft menu={moctail}/>
</div>
<div className="menuleft">
<MenuRight menu={cake}/>
</div>
<div className="menuleft">
<MenuLeft menu={softdrink}/>
</div>
</section>
</section>
    </div>
</body>
     );
}

export default MenuBody;
